<template>
  <section v-if="this.getIsAudience" class="subContainer">
    <div class="fr-container--fluid">
      <div class="fr-grid-row fr-grid-row--gutters fr-px-3w fr-pt-3w fr-mb-4w">
        <div class="fr-col-12 fr-col-lg-9 subContainer__titleSection">
          <div>
            <h1 id="frequentation" class="fr-mb-0">
              Fréquentation des sites de l’État
            </h1>
          </div>
          <div>
            <button id="button-2995" class="fr-btn fr-btn--tertiary-no-outline subContainer__titleSection--icon"
                    aria-describedby="tooltip-2994">
              <span class="fr-icon-question-line" aria-hidden="true"></span>
            </button>
            <span class="fr-tooltip fr-placement" id="tooltip-2994" role="tooltip" aria-hidden="true">
              Interagissez avec les données d'audience des {{ nbSitesVisits }} sites de l'État qui les ont publiées,
              mises à jour tous les jours.
            </span>
          </div>
        </div>
        <div class="fr-col-12 fr-col-lg-3 subContainer__btn">
          <button id="fr-btn-comparer" ref="compareButton" @click="sendPositionCompareButton"
                  class="fr-btn fr-btn--secondary fr-mx-1v subContainer__btn--compare" data-fr-opened="false"
                  aria-controls="fr-modal-comparison" aria-expanded=true title="Comparer">Comparer
          </button>
          <button id="fr-btn-filter" class="fr-btn fr-mx-1v subContainer__btn--filter" @click="openSidenav"
                  aria-expanded=true title="Filtrer">Filtrer
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {endPoint, fetchDataNbSites} from "../services/api";

export default {
  name: "TitleBar",
  data() {
    return {
      nbSitesVisits: 0,
      endpoint: endPoint
    }
  },
  computed: {
    ...mapGetters([
      'getIsAudience',
      'getMinistries',
      'getTypeSites',
      'getSites',
      'getDimensionName',
      'getIsComparativeTag'
    ]),
  },
  methods: {
    ...mapActions([
      'setIsSidenavVisible',
      'setCompareButtonPosition',
      'setIsModalOpen',
      'setDimensionName',
      'setIsDimensionsSegmentSelected',
      'setComparativeTag',
      'setIsComparativeTag',
      'setComparativeParams',
      'setIsDisableDim',
      'setComparativeStartDatePicker'
    ]),

    openSidenav() {
      this.setIsSidenavVisible(true);
      if (this.getIsComparativeTag) {
        this.setComparativeTag(undefined);
        this.setIsComparativeTag(false);
        this.setComparativeParams(undefined);
        this.setComparativeStartDatePicker(undefined);
      }
      if (this.getMinistries === [] && this.getTypeSites === [] && this.getSites === []) {
        this.setIsDimensionsSegmentSelected(false);
        this.setIsDisableDim(true);
      }
    },
    async getNbSites(kind) {
      try {
        let response = await fetchDataNbSites(this.endpoint.nbSites, kind);
        this.nbSitesVisits = response.data.nb_sites;
      } catch (error) {
        console.error("Error in fetchDataNbSites", error);
      }
    },
    sendPositionCompareButton() {
      const compareButton = this.$refs.compareButton.getBoundingClientRect();
      let position = {
        left: `${compareButton.right - 24}`,
        top: `${compareButton.top + compareButton.height + 10}`,
        // à modifier
        right: `${compareButton.right + 24}`,
        leftInit: `${compareButton.left}`
      }
      this.setCompareButtonPosition(position);

      let modal = document.getElementById("fr-btn-comparer");
      let observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.attributeName === 'data-fr-opened') {
            let newValue = modal.getAttribute('data-fr-opened');
            this.setIsModalOpen(newValue);
          }
        });
      });
      observer.observe(modal, {attributes: true});
    },
    myEventHandler() {
      this.sendPositionCompareButton();
    }
  },
  created() {
    window.addEventListener("resize", this.myEventHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  mounted() {
    this.getNbSites("display_visits_page");
  },
};
</script>

<style scoped lang="scss">
@import "../styles/components/titleBar.scss";
</style>