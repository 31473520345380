import {dateDelta, getDatesComparison} from "../utils";
import store from "../store/store";

export const endPoint = {
    //GET
    "labelsIds": `/requests/get_labels_ids`,
    "regionLabels": `/requests/get_regions_labels`,
    "nbSites": `/requests/nb_sites`,
    "dimSites": `/requests/dim_sites`,

    //POST
    "visit": `/requests/query_visite`,
    "visitMovingAverage": `/requests/query_visite_moving_average`,
    "labelsIdsFilterBy": `/requests/get_labels_ids_filterby`,
    "visitsRegionsDepartments": `/requests/visits_regions_departements`,
    "waffleChartData": `/requests/waffle_chart_data`,
    "visitsDevice": `/requests/visits_device`,
    "visitsMinistry": `/requests/visits_ministere`,
    "visitsTypeSite": `/requests/visits_typesite`,
    "visitsSource": `/requests/visits_source`,
    "topSitesPages": `/requests/top_sites_pages`,
    "publishData": `/requests/publish_data`,
    "updateDimSites": `/requests/update_dim_sites`
}

export async function fetchDataTable(endPoint, source) {
    let apiUrl = process.env.VUE_APP_API_URL + endPoint + "?table_name=" + source;
    let fetchResponse = await fetch(apiUrl, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    });
    return await fetchResponse.json();
}

export async function fetchDataNbSites(endPoint, source) {
    let apiUrl = process.env.VUE_APP_API_URL + endPoint + "?kind=" + source;
    let fetchResponse = await fetch(apiUrl, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    });
    return await fetchResponse.json();
}

export async function fetchDataDimSites() {
    let apiUrl = process.env.VUE_APP_API_URL + endPoint.dimSites;
    let fetchResponse = await fetch(apiUrl, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    });
    return await fetchResponse.json();
}

export async function callApi(url, params = {}) {

    let token = '';
    try {
        if (process.env.VUE_APP_KEYCLOAK_AVAILABLE === "true") {
            token = store.getters.getToken.replaceAll('"', '')
        }
    } catch (error) {
        console.error('Error getting token: ', error)
    }


    params = Object.assign({
        mode: 'cors',
        cache: 'no-cache',
    }, params)

    params.headers = Object.assign({
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json'
    }, params.headers)

    let response = await fetch(process.env.VUE_APP_API_URL + url, params)
    let json = await response.json() || {}
    if (!response.ok) {
        let errorMessage = json.error || response.status
        throw new Error(errorMessage)
    }

    return json
}

export async function fetchData(params, endPoint) {
    let apiUrl = process.env.VUE_APP_API_URL + endPoint;
    let fetchResponse = await fetch(apiUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(params)
    });
    let response = await fetchResponse.json();
    return response.status ? response.data.res : [];
}

export async function getQuery(selectedMinistry, selectedTypeSite, selectedSite, period, startDate, endDate) {
    const filterBy = [];
    if (selectedMinistry !== undefined) {
        filterBy.push({
            field: "id_ministere",
            values: [selectedMinistry],
        });
    }
    if (selectedTypeSite !== undefined) {
        filterBy.push({
            field: "id_type_site",
            values: [selectedTypeSite],
        });
    }
    if (selectedSite !== undefined) {
        filterBy.push({
            field: "id_site",
            values: [selectedSite]
        });
    }

    let studyPeriod;
    if (startDate !== undefined && endDate !== undefined) {
        studyPeriod = [{
            date_start: startDate,
            date_end: endDate
        }];
    } else {
        studyPeriod = [{
            date_start: dateDelta(period),
            date_end: dateDelta(1)
        }];
    }

    // interval de temps entre deux dates
    const initialDates = getDatesComparison(
        dateDelta(period), //donne la date du jour moins 31jours
        dateDelta(1) //donne la date d'hier
    );
    let comparisonPeriod = {
        date_start: initialDates.dateStartComparison,
        date_end: initialDates.dateEndComparison,
    };

    const params = {table_name: "ft_visite_sources"};

    if (Object.keys(studyPeriod).length !== 0) {
        params.study_period = studyPeriod;
    }

    let sizeFilter = JSON.stringify(comparisonPeriod).length;
    if (sizeFilter > 2) {
        params.comparison_period = comparisonPeriod;
    }
    sizeFilter = filterBy.length;
    if (sizeFilter > 0) {
        params.filter_by = filterBy;
    }
    await store.dispatch('setParams', params);
}

export async function getDataApi(endPoint, tableName) {
    try {
        let response = await fetchDataTable(endPoint.labelsIds, tableName);
        let data = response.data.res;
        switch (tableName) {
            case "dim_ministere":
                if (store.getters.getMinistries.length === 0) {
                    await store.dispatch('setMinistries', data);
                }
                if (store.getters.getComparativeMinistries.length === 0) {
                    await store.dispatch('setComparativeMinistries', data);
                }
                break;
            case "dim_type_site":
                if (store.getters.getTypeSites.length === 0) {
                    await store.dispatch('setTypeSites', data);
                }
                if (store.getters.getComparativeTypeSites.length === 0) {
                    await store.dispatch('setComparativeTypeSites', data);
                }
                break;
            case "dim_site":
                if (store.getters.getSites.length === 0) {
                    await store.dispatch('setSites', data);
                }
                if (store.getters.getComparativeSites.length === 0) {
                    await store.dispatch('setComparativeSites', data);
                }
                break;
        }
    } catch (error) {
        console.error("Error in fetchDataTable", error);
    }
}

export async function fetchDataFilterByForMinistries(endPoint, typeSite, site) {
    let params = {
        table_name: "dim_site",
        columns: ["id_ministere"],
        filter_by: []
    }
    if (typeSite !== undefined) {
        params.filter_by.push({
            field: "id_type_site",
            values: [typeSite]
        });
    }
    if (site !== undefined) {
        params.filter_by.push({
            field: "id_site",
            values: [site]
        });
    }
    try {
        let data = await fetchData(params, endPoint.labelsIdsFilterBy);
        await store.dispatch('setMinistries', data);
    } catch (error) {
        console.error("Error in fetchDataFilterByForMinistries", error);
    }
}

export async function fetchDataFilterByForTypeSites(endPoint, ministry, site) {
    let params = {
        table_name: "dim_site",
        columns: ["id_type_site"],
        filter_by: []
    }
    if (ministry !== undefined) {
        params.filter_by.push({
            field: "id_ministere",
            values: [ministry]
        });
    }
    if (site !== undefined) {
        params.filter_by.push({
            field: "id_site",
            values: [site]
        });
    }
    try {
        let data = await fetchData(params, endPoint.labelsIdsFilterBy);
        await store.dispatch('setTypeSites', data);
    } catch (error) {
        console.error("Error in fetchDataFilterByForTypeSites", error);
    }
}

export async function fetchDataFilterByForSites(endPoint, ministry, typeSite) {
    let params = {
        table_name: "dim_site",
        columns: ["id_site"],
        filter_by: []
    };
    if (ministry !== undefined) {
        params.filter_by.push({
            field: "id_ministere",
            values: [ministry]
        });
    }
    if (typeSite !== undefined) {
        params.filter_by.push({
            field: "id_type_site",
            values: [typeSite]
        });
    }
    try {
        let data = await fetchData(params, endPoint.labelsIdsFilterBy);
        await store.dispatch('setSites', data);
    } catch (error) {
        console.error("Error in fetchDataFilterByForSites", error);
    }
}

export function getDaysSinceLastMonday() {
    const today = new Date();
    const dayOfWeek = today.getDay(); // 0 pour dimanche, 1 pour lundi, etc.

    let daysSinceMonday;
    if (dayOfWeek === 0) {
        // Si nous sommes dimanche (0), nous devons aller à lundi de la semaine précédente (6 jours en arrière)
        daysSinceMonday = 6;
    } else {
        // Sinon, simplement aller à lundi de cette semaine (dayOfWeek - 1 jours en arrière)
        daysSinceMonday = dayOfWeek - 1;
    }

    // Calculer la date du dernier lundi
    const lastMonday = new Date(today);
    lastMonday.setDate(today.getDate() - daysSinceMonday);

    // Calculer le nombre de jours entre aujourd'hui inclus et le dernier lundi
    const differenceInTime = today.getTime() - lastMonday.getTime();
    return Math.floor(differenceInTime / (1000 * 3600 * 24));
}

export function getDaysSinceFirstOfLastMonth() {
    const today = new Date();
    const currentMonth = today.getMonth(); // Mois actuel (0 pour janvier, 1 pour février, etc.)
    const currentYear = today.getFullYear(); // Année actuelle
    const currentDate = today.getDate(); // Jour actuel

    let lastMonth;
    let lastMonthYear;

    if (currentDate === 1) {
        // Si nous sommes le 1er du mois, nous devons revenir au mois précédent
        lastMonth = currentMonth === 0 ? 11 : currentMonth - 1; // Si janvier, revenir à décembre
        lastMonthYear = currentMonth === 0 ? currentYear - 1 : currentYear; // Si janvier, revenir à l'année précédente
    } else {
        // Sinon, c'est le mois actuel
        lastMonth = currentMonth;
        lastMonthYear = currentYear;
    }

    // Calculer le premier jour du mois précédent
    const firstOfLastMonth = new Date(lastMonthYear, lastMonth, 1);

    // Calculer la différence en jours
    const differenceInTime = today.getTime() - firstOfLastMonth.getTime();
    return Math.floor(differenceInTime / (1000 * 3600 * 24));
}

export function getDaysSinceFirstOfLastYear() {
    const today = new Date();
    const currentYear = today.getFullYear(); // Année actuelle
    const currentDate = today.getDate(); // Jour actuel
    const currentMonth = today.getMonth(); // Mois actuel

    let lastYear;

    if (currentDate === 1 && currentMonth === 0) {
        // Si nous sommes le 1er janvier, nous devons revenir à l'année précédente
        lastYear = currentYear - 1;
    } else {
        // Sinon, c'est l'année actuelle
        lastYear = currentYear;
    }

    // Calculer le premier jour de l'année précédente
    const firstOfLastYear = new Date(lastYear, 0, 1); // 0 pour janvier, 1 pour le premier jour

    // Calculer la différence en jours
    const differenceInTime = today.getTime() - firstOfLastYear.getTime();
    return Math.floor(differenceInTime / (1000 * 3600 * 24));
}

export async function fetchDataChart(params, endPoint) {
    let apiUrl = process.env.VUE_APP_API_URL + endPoint;
    try {
        let fetchResponse = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(params)
        });
        let response = await fetchResponse.json();
        return response.status ? response.data : undefined;

    } catch (error) {
        console.error("Error in fetchDataChart", error);
    }
}

