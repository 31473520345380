const state = {
    isDimensionsSegmentSelected: false,
    compareButtonPosition: undefined,
    compareModalWidth: undefined,
    dimensionName: "Ministère de tutelle",
    selectionComparativeMinistry: undefined,
    selectionComparativeTypeSite: undefined,
    selectionComparativeSite: undefined,
    comparativeMinistries: [],
    comparativeTypeSites: [],
    comparativeSites: [],
    comparativeStartDatePicker: undefined,
    comparativeTag: undefined,
    isComparativeTag: false,
    comparativeParams: undefined,
    isModalOpen: false,
    isDisableDim: true
}

const getters = {
    getIsDimensionsSegmentSelected: state => state.isDimensionsSegmentSelected,
    getCompareButtonPosition: state => state.compareButtonPosition,
    getCompareModalWidth: state => state.compareModalWidth,
    getDimensionName: state => state.dimensionName,
    getSelectionComparativeMinistry: state => state.selectionComparativeMinistry,
    getSelectionComparativeTypeSite: state => state.selectionComparativeTypeSite,
    getSelectionComparativeSite: state => state.selectionComparativeSite,
    getComparativeMinistries: state => state.comparativeMinistries,
    getComparativeTypeSites: state => state.comparativeTypeSites,
    getComparativeSites: state => state.comparativeSites,
    getComparativeStartDatePicker: state => state.comparativeStartDatePicker,
    getComparativeTag: state => state.comparativeTag,
    getIsComparativeTag: state => state.isComparativeTag,
    getComparativeParams: state => state.comparativeParams,
    getIsModalOpen: state => state.isModalOpen,
    getIsDisableDim: state => state.isDisableDim
};

const actions = {
    setIsDimensionsSegmentSelected({commit}, isDimensions) {
        commit('SET_IS_DIMENSIONS_SEGMENT_SELECTED', isDimensions);
    },
    setCompareButtonPosition({commit}, compareButtonPosition) {
        commit('SET_COMPARE_BUTTON_POSITION', compareButtonPosition);
    },
    setCompareModalWidth({commit}, compareModalWidth) {
        commit('SET_COMPARE_MODAL_WIDTH', compareModalWidth);
    },
    setDimensionName({commit}, dimensionName) {
        commit('SET_DIMENSION_NAME', dimensionName);
    },
    setSelectionComparativeMinistry({commit}, selectionComparativeMinistry) {
        commit('SET_SELECTION_COMPARATIVE_MINISTRY', selectionComparativeMinistry);
    },
    setSelectionComparativeTypeSite({commit}, selectionComparativeTypeSite) {
        commit('SET_SELECTION_COMPARATIVE_TYPE_SITE', selectionComparativeTypeSite);
    },
    setSelectionComparativeSite({commit}, selectionComparativeSite) {
        commit('SET_SELECTION_COMPARATIVE_SITE', selectionComparativeSite);
    },
    setComparativeMinistries({commit}, comparativeMinistry) {
        commit('SET_COMPARATIVE_MINISTRIES', comparativeMinistry);
    },
    setComparativeTypeSites({commit}, comparativeTypeSites) {
        commit('SET_COMPARATIVE_TYPE_SITES', comparativeTypeSites);
    },
    setComparativeSites({commit}, comparativeSites) {
        commit('SET_COMPARATIVE_SITES', comparativeSites);
    },
    setComparativeStartDatePicker({commit}, comparativeStartDatePicker) {
        commit('SET_COMPARATIVE_START_DATE_PICKER', comparativeStartDatePicker);
    },
    setComparativeTag({commit}, comparativeTag) {
        commit('SET_COMPARATIVE_TAG', comparativeTag);
    },
    setIsComparativeTag({commit}, isComparativeTag) {
        commit('SET_IS_COMPARATIVE_TAG', isComparativeTag);
    },
    removeComparativeTag({commit}) {
        commit('REMOVE_COMPARATIVE_TAG');
    },
    setComparativeParams({commit}, comparativeParams) {
        commit('SET_COMPARATIVE_PARAMS', comparativeParams);
    },
    setIsModalOpen({commit}, isModalOpen) {
        commit('SET_IS_MODAL_OPEN', isModalOpen);
    },
    setIsDisableDim({commit}, isDisableDim) {
        commit('SET_IS_DISABLE_DIM', isDisableDim);
    }
}

const mutations = {
    SET_IS_DIMENSIONS_SEGMENT_SELECTED(state, isDimensions) {
        state.isDimensionsSegmentSelected = isDimensions;
    },
    SET_COMPARE_BUTTON_POSITION(state, compareButtonPosition) {
        state.compareButtonPosition = compareButtonPosition;
    },
    SET_COMPARE_MODAL_WIDTH(state, compareModalWidth) {
        state.compareModalWidth = compareModalWidth;
    },
    SET_DIMENSION_NAME(state, dimensionName) {
        state.dimensionName = dimensionName;
    },
    SET_SELECTION_COMPARATIVE_MINISTRY(state, selectionComparativeMinistry) {
        state.selectionComparativeMinistry = selectionComparativeMinistry;
    },
    SET_SELECTION_COMPARATIVE_TYPE_SITE(state, selectionComparativeTypeSite) {
        state.selectionComparativeTypeSite = selectionComparativeTypeSite;
    },
    SET_SELECTION_COMPARATIVE_SITE(state, selectionComparativeSite) {
        state.selectionComparativeSite = selectionComparativeSite;
    },
    SET_COMPARATIVE_MINISTRIES(state, comparativeMinistry) {
        state.comparativeMinistries = comparativeMinistry;
    },
    SET_COMPARATIVE_TYPE_SITES(state, comparativeTypeSites) {
        state.comparativeTypeSites = comparativeTypeSites;
    },
    SET_COMPARATIVE_SITES(state, comparativeSites) {
        state.comparativeSites = comparativeSites;
    },
    SET_COMPARATIVE_START_DATE_PICKER(state, comparativeStartDatePicker) {
        state.comparativeStartDatePicker = comparativeStartDatePicker;
    },
    SET_COMPARATIVE_TAG(state, comparativeTag) {
        state.comparativeTag = comparativeTag;
    },
    SET_IS_COMPARATIVE_TAG(state, isComparativeTag) {
        state.isComparativeTag = isComparativeTag;
    },
    REMOVE_COMPARATIVE_TAG(state) {
        state.comparativeTag = undefined;
    },
    SET_COMPARATIVE_PARAMS(state, comparativeParams) {
        state.comparativeParams = comparativeParams;
    },
    SET_IS_MODAL_OPEN(state, isModalOpen) {
        state.isModalOpen = isModalOpen;
    },
    SET_IS_DISABLE_DIM(state, isDisableDim) {
        state.isDisableDim = isDisableDim;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};