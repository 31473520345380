export function dsfrAnalytics (params = {}) {    
    try {
        localStorage.setItem("dsfr_analytics", "available");
        try {
            if(_EA_disabled() !== 1 && localStorage.getItem("tarteaucitron") === "true"){

                window.dsfr.analytics.page.path = params.path;
                window.dsfr.analytics.page.title = params.title;
                window.dsfr.analytics.page.name = params.name;
                window.dsfr.analytics.page.theme = params.theme;
                // window.dsfr.analytics.page.date = getData();

                if ("isError" in params){
                    window.dsfr.analytics.site.error = params.isError;
                }
                // Envoi des données                
                window.dsfr.analytics.isDebugging = (process.env.VUE_APP_DEBUGGING === "true")
                window.dsfr.analytics.collect();
            }else console.log("Eulerian disabled")

        } catch (error) {
            console.error('Error: ', error)
        }
    }
    catch (err) {
        console.warn("Dsfr analytics failed to be set; Cookies Blocked!");
    }

}
export default dsfrAnalytics

export function EA_push (params = {}) {    
    try {
        localStorage.setItem("dsfr_analytics", "available");
        try {
            if(_EA_disabled() !== 1 && localStorage.getItem("tarteaucitron") === "true"){
                window.EA_datalayer = [];            
                // Informations de page :
                window.EA_datalayer.push('path', params.path);
                window.EA_datalayer.push('title', params.title);
                window.EA_datalayer.push('name', params.name);
                window.EA_datalayer.push('theme', params.theme);
                window.EA_datalayer.push('site-segment', 'audience-communication');
                window.EA_datalayer.push('page_template', 'audience-communication');
                window.EA_datalayer.push('pagegroup', 'audience-communication');
                window.EA_datalayer.push('pagelabel', ['audience-communication', '', '', '', '']);
                window.EA_datalayer.push('page_date', getData());

                // Informations de site :
                window.EA_datalayer.push('site_entity', 'SPM || SIG');
                window.EA_datalayer.push('site_environment', process.env.VUE_APP_ENV);
                window.EA_datalayer.push('site_target', 'information');
                window.EA_datalayer.push('site_type', 'standard');

                if ("isError" in params){
                    window.EA_datalayer.push('error', params.isError);
                }

                // Envoi des données :
                window.EA_push(window.EA_datalayer); 
            }else console.log("Eulerian disabled")

        } catch (error) {
            console.error('Error: ', error)
        }
    }
    catch (err) {
        console.warn("Dsfr analytics failed to be set; Cookies Blocked!");
    }

}

export function getData () {
    const newDate = new Date();
    newDate.setDate( (new Date()).getDate() )
    return newDate.toISOString().split('T')[0]
}