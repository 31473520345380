<template>
  <dialog
      aria-labelledby="fr-modal-title-modal-comparison"
      role="dialog"
      id="fr-modal-comparison"
      class="fr-modal modal-compare"
  >
    <div class="fr-container fr-container--fluid fr-container-md" :style=styleDiv>
      <div class="fr-grid-row">
        <div class="fr-col-12 fr-col-lg-6">
          <div class="fr-modal__body fr-col-8" ref="compareModal">
            <div class="fr-modal__header fr-p-3w">
              <fieldset class="fr-segmented">
                <div class="fr-segmented__elements">
                  <div class="fr-segmented__element">
                    <input value="1"
                           :checked="this.getIsDimensionsSegmentSelected"
                           type="radio"
                           id="segmented-2230-1"
                           name="segmented-2230"
                           @click="handleSelectedSegment(true)"
                           :disabled="this.getIsDisableDim">
                    <label class="fr-label" for="segmented-2230-1">
                      Dimensions
                    </label>
                  </div>
                  <div class="fr-segmented__element">
                    <input value="2"
                           :checked="!this.getIsDimensionsSegmentSelected"
                           type="radio"
                           id="segmented-2230-2"
                           name="segmented-2230"
                           @click="handleSelectedSegment(false)">
                    <label class="fr-label" for="segmented-2230-2">
                      Période
                    </label>
                  </div>
                </div>
              </fieldset>
            </div>

            <div class="fr-modal__content fr-m-0 fr-px-3w">
              <div v-if="this.getIsDimensionsSegmentSelected" class="fr-select-group fr-pb-3w fr-pt-3v fr-m-0">
                <fieldset class="modal-compare__fieldset fr-mx-0 fr-p-0">
                  <label class="fr-label" for="select">
                    {{ this.getDimensionName }}
                  </label>
                  <select
                      v-if="this.getDimensionName === 'Ministère de tutelle' || this.getDimensionName === ''"
                      v-model="selectionComparativeMinistry"
                      class="fr-select"
                      id="select"
                      name="select"
                      title="Sélectionner un ministère"
                  >
                    <option value="undefined" selected disabled hidden>Sélectionner un ministère</option>
                    <option v-for="t in comparativeMinistries" :value="t['value']" :key="t['value']">
                      {{ t["label"] }}
                    </option>
                  </select>
                  <select
                      v-if="this.getDimensionName === 'Type de site'"
                      v-model="selectionComparativeTypeSite"
                      class="fr-select"
                      id="select"
                      name="select"
                      title="Sélectionner un type de site"
                  >
                    <option value="undefined" selected disabled hidden>Sélectionner un type de site</option>
                    <option v-for="t in comparativeTypeSites" :value="t['value']" :key="t['value']">
                      {{ t["label"] }}
                    </option>
                  </select>
                  <select
                      v-if="this.getDimensionName === 'Site'"
                      v-model="selectionComparativeSite"
                      class="fr-select"
                      id="select"
                      name="select"
                      title="Sélectionner un site"
                  >
                    <option value="undefined" selected disabled hidden>Sélectionner un site</option>
                    <option v-for="t in comparativeSites" :value="t['value']" :key="t['value']">
                      {{ t["label"] }}
                    </option>
                  </select>
                </fieldset>
              </div>

              <div v-else>
                <fieldset class="modal-compare__fieldset fr-pb-3w fr-pt-3v fr-px-0 fr-m-0"
                          id="label-hint"
                          aria-labelledby="text-label-hint-legend text-input-start-date-messages"
                >
                  <div class="fr-fieldset__element fr-mx-0 fr-p-0">
                    <div class="fr-input-group">
                      <label class="fr-label" for="text-input-start-date">
                        Début
                      </label>
                      <input class="fr-input fr-input--error"
                             aria-describedby="text-input-date-messages"
                             id="text-input-start-date"
                             type="date"
                             v-model="comparativeStartDatePicker"
                             @change="changeComparativeStartDate"
                             title="Afficher le sélecteur de dates"
                      />
                      <p v-if="!isNotRightComparativeStartDate && !isNotRightSelectedDate"
                         id="text-input-info-filter"
                         class="fr-message--info fr-text--xs fr-m-0 fr-pt-2w">
                        La période que vous comparez est identique à celle des filtres
                      </p>
                      <p v-if="isNotRightComparativeStartDate"
                         id="text-input-error-previous-date"
                         class="fr-message--error fr-text--xs fr-m-0 fr-pt-2w">
                        Il faut selectionner une date antérieur à {{ validComparedStartDate }} pour faire une
                        comparaison
                      </p>
                      <p v-if="isNotRightSelectedDate"
                         id="text-input-error-date"
                         class="fr-message--error fr-text--xs fr-m-0 fr-pt-2w">
                        La date séléctionnée n'est pas valide
                      </p>
                      <div class="fr-messages-group" id="text-input-start-date-messages" aria-live="assertive">
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
              <div class="fr-py-3w">
                <button
                    id="fr-btn-valider"
                    class="fr-btn position-right fr-px-2w fr-py-1w center-button"
                    title="Valider"
                    aria-controls="fr-modal-comparison"
                    @click="sendTag"
                >Valider
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </dialog>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {endPoint, getDataApi} from "../../services/api";
import {calculateEndDate, convertDate, convertDateSlash, dateDelta, findLabel} from "../../utils";

export default {
  name: "ModalComparison",
  data() {
    return {
      validComparedStartDate: "",
      isNotRightComparativeStartDate: false,
      isNotRightSelectedDate: false,
      widthModal: '',
      isDisable: false
    }
  },
  computed: {
    ...mapGetters([
      'getCompareButtonPosition',
      'getCompareModalWidth',
      'getDimensionName',
      'getSelectedMinistry',
      'getSelectedTypeSite',
      'getSelectedSite',
      'getIsDimensionsSegmentSelected',
      'getSelectionComparativeMinistry',
      'getSelectionComparativeTypeSite',
      'getSelectionComparativeSite',
      'getComparativeMinistries',
      'getComparativeTypeSites',
      'getComparativeSites',
      'getComparativeStartDatePicker',
      'getPeriod',
      'getIsDisableDim'
    ]),
    selectionComparativeMinistry: {
      get() {
        return this.getSelectionComparativeMinistry;
      },
      set(value) {
        this.setSelectionComparativeMinistry(value);
      }
    },
    selectionComparativeTypeSite: {
      get() {
        return this.getSelectionComparativeTypeSite;
      },
      set(value) {
        this.setSelectionComparativeTypeSite(value);
      }
    },
    selectionComparativeSite: {
      get() {
        return this.getSelectionComparativeSite;
      },
      set(value) {
        this.setSelectionComparativeSite(value);
      }
    },
    comparativeMinistries: {
      get() {
        return this.getComparativeMinistries;
      },
    },
    comparativeTypeSites: {
      get() {
        return this.getComparativeTypeSites;
      }
    },
    comparativeSites: {
      get() {
        return this.getComparativeSites;
      }
    },
    comparativeStartDatePicker: {
      get() {
        return this.getComparativeStartDatePicker;
      },
      set(value) {
        this.setComparativeStartDatePicker(value);
      }
    },
    styleDiv: {
      get() {
        return this.generateStyleComparisonModal();
      },
      set(value) {
        this.setCompareButtonPosition(value);
      }
    }
  },
  methods: {
    ...mapActions([
      'setCompareModalWidth',
      'setIsDimensionsSegmentSelected',
      'setSelectionComparativeMinistry',
      'setSelectionComparativeTypeSite',
      'setSelectionComparativeSite',
      'setComparativeStartDatePicker',
      'setComparativeTag',
      'setCompareButtonPosition',
      'setIsComparativeTag',
      'setComparativeParams',
      'setDimensionName'
    ]),
    handleSelectedSegment(isDimensions) {
      this.setIsDimensionsSegmentSelected(isDimensions);
    },
    changeComparativeStartDate() {
      this.isNotRightComparativeStartDate = false;
      let comparativeEndDate = calculateEndDate(this.getComparativeStartDatePicker, this.getPeriod);
      if (new Date(convertDate(this.validComparedStartDate)) <= new Date(this.getComparativeStartDatePicker)) {
        this.isNotRightComparativeStartDate = true;
      }
      this.isNotRightSelectedDate = new Date("2023-10-02") > new Date(comparativeEndDate);
    },
    generateComparativeParams(dimension, comparisonLabel, period) {

      let comparativeParams = {
        table_name: "ft_visite_sources",
        study_period: [
          {
            date_start: dateDelta(period),
            date_end: dateDelta(1)
          }
        ],
        filter_by: []
      }

      if (this.getDimensionName.includes("Ministère de tutelle")) {
        comparativeParams.filter_by.push({
          field: "id_ministere",
          values: [dimension, comparisonLabel]
        });
      }
      if (this.getDimensionName.includes("Type de site")) {
        comparativeParams.filter_by.push({
          field: "id_type_site",
          values: [dimension, comparisonLabel]
        });
      }
      if (this.getDimensionName.includes("Site")) {
        comparativeParams.filter_by.push({
          field: "id_site",
          values: [dimension, comparisonLabel]
        });
      }
      if (dimension !== undefined && dimension.includes("period")) {
        comparativeParams.study_period.push({
          date_start: comparisonLabel
        });
        delete comparativeParams.filter_by;
      }
      return comparativeParams;
    },
    sendTag() {
      let comparisonLabel;
      let comparativeParams;
      if (this.getIsDimensionsSegmentSelected && !this.isFilterUndefined()) {
        let dimension;

        if (this.getSelectionComparativeMinistry !== undefined && this.getDimensionName.includes("Ministère de tutelle")) {
          dimension = this.getSelectedMinistry;
          comparisonLabel = this.getSelectionComparativeMinistry;
          this.setComparativeTag({
            label: findLabel(this.getComparativeMinistries, this.getSelectionComparativeMinistry),
            role: "comparativeDimensions"
          });
        }
        if (this.getSelectionComparativeTypeSite !== undefined && this.getDimensionName.includes("Type de site")) {
          dimension = this.getSelectedTypeSite;
          comparisonLabel = this.getSelectionComparativeTypeSite;
          this.setComparativeTag({
            label: findLabel(this.getComparativeTypeSites, this.getSelectionComparativeTypeSite),
            role: "comparativeDimensions"
          });
        }
        if (this.getSelectionComparativeSite !== undefined && this.getDimensionName.includes("Site")) {
          dimension = this.getSelectedSite;
          comparisonLabel = this.getSelectionComparativeSite;
          this.setComparativeTag({
            label: findLabel(this.getComparativeSites, this.getSelectionComparativeSite),
            role: "comparativeDimensions"
          });
        }
        comparativeParams = this.generateComparativeParams(dimension, comparisonLabel, this.getPeriod);
        this.setComparativeParams(comparativeParams);
        this.setIsComparativeTag(true);
      } else {
        if (this.getComparativeStartDatePicker !== undefined) {
          comparativeParams = this.generateComparativeParams("period", this.getComparativeStartDatePicker, this.getPeriod);
          let comparativeEndDate = calculateEndDate(this.getComparativeStartDatePicker, this.getPeriod);
          comparisonLabel = convertDate(this.getComparativeStartDatePicker).replaceAll("-", "/") + " - "
              + convertDate(comparativeEndDate).replaceAll("-", "/");
          if (!this.isNotRightComparativeStartDate && !this.isNotRightSelectedDate) {
            this.setComparativeTag({label: comparisonLabel, role: "comparativePeriod"});
            this.setComparativeParams(comparativeParams);
            this.setIsComparativeTag(true);
          }
        }
      }
    },
    isFilterUndefined() {
      return this.getSelectedMinistry === undefined && this.getSelectedTypeSite === undefined && this.getSelectedSite === undefined;
    },
    calculateWidthModal() {
      const compareModal = this.$refs.compareModal.getBoundingClientRect();
      this.setCompareModalWidth(compareModal.width);
    },
    generateStyleComparisonModal() {
      let style;
      if (this.getCompareButtonPosition !== undefined) {
        if(window.innerWidth > 1247) {
          let calculateLeft = this.getCompareButtonPosition.left - this.getCompareModalWidth;
          style = {
            position: "absolute",
            left: calculateLeft + "px",
            top: this.getCompareButtonPosition.top + "px"
          }
        }
        if (window.innerWidth <= 1247 && window.innerWidth >= 1024) {
          let calculateLeft = this.getCompareButtonPosition.left - this.getCompareModalWidth;
          style = {
            position: "absolute",
            left: calculateLeft + "px",
            top: this.getCompareButtonPosition.top + "px"
          }
        }
        if (window.innerWidth < 1024) {
          style = {
            position: "absolute",
            left: this.getCompareButtonPosition.leftInit + "px",
            top: this.getCompareButtonPosition.top + "px"
          }
        }
      }
      return style;
    },
    myEventHandler() {
      this.calculateWidthModal();
    }
  },
  created() {
    window.addEventListener("resize-modal", this.myEventHandler);
  },
  destroyed() {
    window.removeEventListener("resize-modal", this.myEventHandler);
  },
  mounted() {
    getDataApi(endPoint, "dim_ministere");
    this.validComparedStartDate = convertDateSlash(dateDelta(this.getPeriod));
    this.calculateWidthModal();
  }
}
</script>

<style scoped lang="scss">
@import "../../styles/components/modals/modalComparison.scss";
</style>