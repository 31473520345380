const state = {
    userName: '',
    isSuperAdmin: false,
    token: ''
};

const getters = {
    getUserName: (state) => state.userName,
    getIsSuperAdmin: (state) => state.isSuperAdmin,
    getToken: (state) => state.token
};

const actions = {
    setUserName({commit}, name) {
        commit('SET_USER_NAME', name);
    },
    setIsSuperAdmin({commit}, status){
        commit('SET_IS_SUPER_ADMIN', status);
    },
    setToken({commit}, token){
        commit('SET_TOKEN', token);
    }
}

const mutations = {

    SET_USER_NAME(state, name) {
        state.userName = name;
    },
    SET_IS_SUPER_ADMIN(state, status) {
        state.isSuperAdmin = status.includes("superAdmin");
    },
    SET_TOKEN(state, token) {
        state.token = token;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};