<template>

  <header role="banner" class="fr-header">
    <div class="fr-header__body">
      <div class="fr-container--fluid">
        <div class="fr-header__body-row">
          <div class="fr-header__brand">
            <div class="fr-header__brand-top fr-px-2w">
              <div class="fr-header__logo" target="_blank">
                <a href="https://www.info.gouv.fr/" class="fr-logo" title="Lien vers le site gouvernement.fr">
                  Gouvernement
                </a>
              </div>
              <div class="fr-header__navbar" id="modal-491" aria-labelledby="button-492">
                <button class="fr-btn--menu fr-btn" data-fr-opened="false" aria-controls="modal-491"
                        aria-haspopup="menu" aria-expanded="false" id="button-492" title="Menu">
                  Menu
                </button>
              </div>
            </div>
            <div class="fr-header__service">
              <div class="fr-px-2w">
                <router-link id="fr-header-link-accueil" :to="{ name: 'accueil' }"
                             title=" L’Observatoire des données des sites Internet de l’État - Accueil">
                  <p class="fr-header__service-title">
                    Observatoire des sites Internet de l’État
                  </p>
                </router-link>
              </div>
            </div>
          </div>
          <div class="fr-header__tools">
            <div class="fr-header__tools-links fr-px-md-2w">
              <ul class="fr-btns-group">
                <li>
                  <button id="fr-btn-connect"
                          class="fr-btn fr-btn--tertiary fr-btn--icon-left fr-icon-account-circle-fill fr-text--sm"
                          v-if="!this.isAuthenticated" @click="login" aria-hidden="true" title="Me connecter">Me
                    connecter
                  </button>
                </li>
                <li>
                  <button v-if="this.isAuthenticated && this.getIsSuperAdmin" id="fr-btn-espace"
                          class="fr-btn fr-btn--tertiary fr-btn--icon-left fr-icon-account-circle-fill fr-text--sm">
                    <router-link :to="{ name: 'admin-home' }"
                                 target="_self" title="Mon espace">
                      Mon espace
                    </router-link>
                  </button>
                </li>
                <li>
                  <button id="fr-btn-disconnect"
                          class="fr-btn fr-btn--tertiary fr-btn--icon-left fr-icon-logout-box-r-line fr-text--sm"
                          v-if="this.isAuthenticated" @click="logout" title="Se déconnecter">Se déconnecter
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="fr-header__menu fr-modal" id="modal-491" aria-labelledby="button-492">
      <div class="fr-container fr-ml-md-1w">
        <button class="fr-btn--close fr-btn" aria-controls="modal-491" title="Fermer">
          Fermer
        </button>
        <div class="fr-header__menu-links"></div>
        <app-navigation></app-navigation>
      </div>
    </div>
  </header>
</template>

<script>
import navigationDsfr from "./Navigation.vue";
import {mapGetters} from "vuex";

export default {
  name: "headerDsfr",
  components: {
    "app-navigation": navigationDsfr,
  },
  computed: {
    ...mapGetters([
      'getIsSuperAdmin'
    ]),
    isAuthenticated() {
      return this.$keycloak?.authenticated;
    }
  },
  methods: {
    logout() {
      return this.$keycloak?.logoutFn();
    },
    login() {
      return this.$keycloak?.loginFn();
    }
  },
  mounted() {
    if (this.$keycloak) {
      this.$store.dispatch('setUserName', this.$keycloak.userName);
      this.$keycloak.realmAccess?.roles && this.$store.dispatch('setIsSuperAdmin', this.$keycloak.realmAccess.roles);
      this.$store.dispatch('setToken', this.$keycloak.token);
    }
  }
};
</script>

<style scoped lang="scss">
@import "../styles/components/header.scss";
</style>
