<template>
  <!-- Liens directs uniquement -->
  <nav class="fr-nav" id="navigation-476" role="navigation" aria-label="Menu principal">
    <ul class="fr-nav__list">
      <li class="fr-nav__item">
        <router-link id="fr-nav-accueil" :to="{ name: 'accueil' }" class="fr-nav__link" target="_self"
          title="Accueil">Accueil</router-link>
      </li>
      <li class="fr-nav__item">
        <router-link id="fr-nav-visites" :to="{ name: 'visites' }" class="fr-nav__link" target="_self"
          title="Audience">Audience</router-link>
      </li>
      <li class="fr-nav__item">
        <router-link id="fr-nav-sites" :to="{ name: 'sites' }" class="fr-nav__link" target="_self"
          title="Panorama">Panorama</router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'navigationDsfr'
}
</script>
