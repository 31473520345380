import {sortTags} from "../../utils";

const state = {
    isSidenavVisible: false,
    defaultTags: [
        {label: "Tous les ministères", role: "allMinistries"},
        {label: "Mois glissant", role: "currentDate"}
    ],
    tags: [],
    selectedSite: undefined,
    selectedMinistry: undefined,
    selectedTypeSite: undefined,
    params: {},
    ministries: [],
    typeSites: [],
    sites: [],
    selectedPeriod: "",
    period: "31",
    startDatePicker: undefined,
    endDatePicker: undefined
};

const getters = {
    getIsSidenavVisible: (state) => state.isSidenavVisible,
    getDefaultTags: (state) => state.defaultTags,
    getTags: (state) => state.tags,
    getSelectedSite: state => state.selectedSite,
    getSelectedMinistry: state => state.selectedMinistry,
    getSelectedTypeSite: state => state.selectedTypeSite,
    getParams: state => state.params,
    getMinistries: state => state.ministries,
    getTypeSites: state => state.typeSites,
    getSites: state => state.sites,
    getSelectedPeriod: state => state.selectedPeriod,
    getPeriod: state => state.period,
    getStartDatePicker: state => state.startDatePicker,
    getEndDatePicker: state => state.endDatePicker,
};

const actions = {
    setIsSidenavVisible({commit}, isSidenavVisible) {
        commit('SET_IS_SIDENAV_VISIBLE', isSidenavVisible);
    },
    removeDefaultTag({commit}, tag) {
        commit('REMOVE_DEFAULT_TAG', tag);
    },
    removeTag({commit}, tag) {
        commit('REMOVE_TAG', tag);
    },
    addDefaultTag({commit}, tag) {
        commit('ADD_DEFAULT_TAG', tag);
    },
    addTag({commit}, tag) {
        commit('ADD_TAG', tag);
    },
    setSelectedSite({commit}, site) {
        commit('SET_SELECTED_SITE', site);
    },
    setSelectedMinistry({commit}, ministry) {
        commit('SET_SELECTED_MINISTRY', ministry);
    },
    setSelectedTypeSite({commit}, typeSite) {
        commit('SET_SELECTED_TYPE_SITE', typeSite);
    },
    removeTagsSites({commit}) {
        commit('REMOVE_TAGS_SITES');
        commit('RESET_SELECTED_SITE');
        commit('RESET_SELECTED_MINISTRY');
        commit('RESET_SELECTED_TYPE_SITE');
        commit('RESET_MINISTRIES');
        commit('RESET_TYPE_SITES');
        commit('RESET_SITES');
    },
    removeTagsCurrentDate({commit}) {
        commit('REMOVE_TAGS_CURRENT_DATE');
    },
    setParams({commit}, params) {
        commit('SET_PARAMS', params);
    },
    setMinistries({commit}, ministries) {
        commit('SET_MINISTRIES', ministries);
    },
    setTypeSites({commit}, typeSites) {
        commit('SET_TYPE_SITES', typeSites);
    },
    setSites({commit}, sites) {
        commit('SET_SITES', sites);
    },
    setSelectedPeriod({commit}, selectedPeriod) {
        commit('SET_SELECTED_PERIOD', selectedPeriod);
    },
    setPeriod({commit}, period) {
        commit('SET_PERIOD', period);
    },
    setStartDatePicker({commit}, startDatePicker) {
        commit('SET_START_DATE_PICKER', startDatePicker);
    },
    setEndDatePicker({commit}, endDatePicker) {
        commit('SET_END_DATE_PICKER', endDatePicker);
    },
}

const mutations = {
    SET_IS_SIDENAV_VISIBLE(state, isSidenavVisible) {
        state.isSidenavVisible = isSidenavVisible;
    },
    REMOVE_DEFAULT_TAG(state, defaultTag) {
        state.defaultTags = state.defaultTags.filter(existingTag => existingTag.role !== defaultTag.role);
    },
    REMOVE_TAG(state, tag) {
        state.tags = state.tags.filter(existingTag => existingTag.role !== tag.role);
    },
    ADD_DEFAULT_TAG(state, defaultTag) {
        state.defaultTags = state.defaultTags.filter(existingTag => existingTag.role !== defaultTag.role);
        state.defaultTags.push(defaultTag);
    },
    ADD_TAG(state, tag) {
        state.tags = state.tags.filter(existingTag => existingTag.role !== tag.role);
        state.tags.push(tag);
        sortTags(state.tags);
    },
    REMOVE_TAGS_SITES(state) {
        state.tags = state.tags.filter(existingTag => existingTag.role !== "ministry" && existingTag.role !== "typeSite" && existingTag.role !== "site");
    },
    SET_SELECTED_SITE(state, site) {
        state.selectedSite = site;
    },
    SET_SELECTED_MINISTRY(state, ministry) {
        state.selectedMinistry = ministry;
    },
    SET_SELECTED_TYPE_SITE(state, typeSite) {
        state.selectedTypeSite = typeSite;
    },
    RESET_SELECTED_SITE(state) {
        state.selectedSite = undefined;
    },
    RESET_SELECTED_MINISTRY(state) {
        state.selectedMinistry = undefined;
    },
    RESET_SELECTED_TYPE_SITE(state) {
        state.selectedTypeSite = undefined;
    },
    SET_PARAMS(state, params) {
        state.params = params;
    },
    SET_MINISTRIES(state, ministries) {
        state.ministries = ministries;
    },
    SET_TYPE_SITES(state, typeSites) {
        state.typeSites = typeSites;
    },
    SET_SITES(state, sites) {
        state.sites = sites;
    },
    RESET_MINISTRIES(state) {
        state.ministries = [];
    },
    RESET_TYPE_SITES(state) {
        state.typeSites = [];
    },
    RESET_SITES(state) {
        state.sites = [];
    },
    SET_SELECTED_PERIOD(state, selectedPeriod) {
        state.selectedPeriod = selectedPeriod;
    },
    SET_PERIOD(state, period) {
        state.period = period;
    },
    REMOVE_TAGS_CURRENT_DATE(state) {
        state.tags = state.tags.filter(existingTag => existingTag.role !== 'currentDate');
    },
    SET_START_DATE_PICKER(state, startDatePicker) {
        state.startDatePicker = startDatePicker;
    },
    SET_END_DATE_PICKER(state, endDatePicker) {
        state.endDatePicker = endDatePicker;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};