import Vue from 'vue';
import Vuex from 'vuex';
import sidenav from "./modules/sidenav";
import filters from "./modules/filters";
import comparison from './modules/comparison';
import token from './modules/token';

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        filters,
        sidenav,
        comparison,
        token
    }
})

export default store;