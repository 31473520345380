const state = {
    isAudience: false,
};

const getters = {
    getIsAudience: (state) => state.isAudience,
};

const actions = {
    setIsAudience({ commit }, value) {
            commit('SET_IS_AUDIENCE', value);
    }
}

const mutations = {
    SET_IS_AUDIENCE(state, isAudience) {
        state.isAudience = isAudience;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};